<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardBody :v-show="showSettlementDetails">
          <CRow class="details-header mx-2 mb-3">
            <CCol md="9" sm="9" class="px-0">
              <h5 class="float-left">{{ $t("SETTLEMENTS.TABLE.3") }}:</h5>
              <div class="details-header-title float-left">
                {{ $route.params.id }}
              </div>
            </CCol>
            <CCol md="3" sm="3">
              <vue-json-to-csv
                :labels="getCSVLables()"
                :json-data="computeCSVData()"
                :csv-title="getCSVName('settlements_details')"
                class="px-2 float-right"
              >
                <CButton
                  size="sm"
                  color="lightborder"
                  class="float-right"
                  @click="computeCSVData()"
                >
                  <CIcon name="cil-cloud-download" />&nbsp;{{
                    $t("COMMON.EXPORT")
                  }}
                </CButton>
              </vue-json-to-csv>
            </CCol>
          </CRow>
          <CDataTable small fixed :items="visibleData" :fields="detailFields">
          </CDataTable>
          <div v-if="payment_details !== null">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("PAYMENTS.TABLE.3") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.13") }}</th>
                    <th>{{ $t("ORDERS.TABLE.3") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.14") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.18") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.2") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.17") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.9") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.5") }}</th>
                    <th>{{ $t("SETTLEMENTS.TABLE.12") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in payment_details" :key="index">
                    <td>
                      <div v-on:click="goTo('/payments/', row.payment_id)">
                        <p class="view-more">{{ row.payment_id }}</p>
                      </div>
                    </td>
                    <td>{{ row.merchant_payment_reference }}</td>
                    <td>
                      <div v-on:click="goTo('/orders/', row.order_id)">
                        <p class="view-more">{{ row.order_id }}</p>
                      </div>
                    </td>
                    <td>{{ row.merchant_order_reference }}</td>
                    <td v-if="row.amount">
                      {{ formatAmount(row.payment_amount) }}
                    </td>
                    <td v-if="row.settlement_amount">
                      {{ formatAmount(row.settlement_amount) }}
                    </td>
                    <td>{{ formatAmount(row.payment_discount) }}</td>
                    <td>
                      {{ formatAmount(row.total_settlement_fee) }}
                    </td>
                    <td>{{ row.currency }}</td>
                    <td>{{ formatDate(row.payment_date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton color="durianprimary" class="mr-3" @click="goBack">{{
            $t("COMMON.BACK_BUTTON")
          }}</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {
  getSettlement,
  exportSettlementDetails,
} from "@/api/settlement.api.js";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "SettlementDetails",
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      selected: null,
      payment_details: [],
      showSettlementDetails: false,
      totalRows: 0,
      CSVLabels: {
        payment_id: {},
        merchant_payment_reference: {},
        order_id: {},
        merchant_order_reference: {},
        amount: {},
        settlement_amount: {},
        discount: {},
        total_settlement_fee: {},
        currency: {},
        payment_date: {},
        settled_at: {},
        group: {},
      },
    };
  },
  methods: {
    getSettlementDetailsByID() {
      getSettlement(this.$route.params.id)
        .then((response) => {
          this.selected = response.data.data;
          this.selected["settlement_amount"] = this.formatAmount(
            this.selected["settlement_amount"]
          );
          this.selected["total_amount"] = this.formatAmount(
            this.selected["total_amount"]
          );
          this.selected["total_settlement_fee"] = this.formatAmount(
            this.selected["total_settlement_fee"]
          );
          this.selected["settlement_date"] = this.formatDate(
            this.selected["settlement_date"]
          );
          this.totalRows = this.selected["payment_details"].length;
          this.payment_details = Object.assign(
            {},
            this.selected["payment_details"]
          );
          this.showSettlementDetails = true;
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },
    getCSVLables() {
      (this.CSVLabels.payment_id.title = this.$t("SETTLEMENTS.CSV.PAYMENT_ID")),
        (this.CSVLabels.merchant_payment_reference.title = this.$t(
          "SETTLEMENTS.CSV.PAYMENT_REFERENCE"
        )),
        (this.CSVLabels.order_id.title = this.$t("SETTLEMENTS.CSV.ORDER_ID")),
        (this.CSVLabels.merchant_order_reference.title = this.$t(
          "SETTLEMENTS.CSV.ORDER_REFERENCE"
        )),
        (this.CSVLabels.amount.title = this.$t("SETTLEMENTS.CSV.TOTAL_AMOUNT")),
        (this.CSVLabels.settlement_amount.title = this.$t(
          "SETTLEMENTS.CSV.SETTLEMENT_AMOUNT"
        )),
        (this.CSVLabels.discount.title = this.$t("SETTLEMENTS.CSV.DISCOUNT")),
        (this.CSVLabels.total_settlement_fee.title = this.$t(
          "SETTLEMENTS.CSV.TOTAL_FEE"
        )),
        (this.CSVLabels.currency.title = this.$t("SETTLEMENTS.CSV.CURRENCY")),
        (this.CSVLabels.group.title = this.$t("SETTLEMENTS.CSV.GROUP")),
        (this.CSVLabels.payment_date.title = this.$t(
          "SETTLEMENTS.CSV.PAYMENT_DATE"
        )),
        (this.CSVLabels.settled_at.title = this.$t(
          "SETTLEMENTS.CSV.SETTLEMENT_DATE"
        ));
      return this.CSVLabels;
    },
    getLabelKey(key) {
      switch (key) {
        case "total_amount":
          return this.$t("SETTLEMENTS.TABLE.6");
        case "settlement_amount":
          return this.$t("SETTLEMENTS.TABLE.7");
        case "total_settlement_fee":
          return this.$t("SETTLEMENTS.TABLE.8");
        case "settlement_fee":
          return this.$t("SETTLEMENTS.TABLE.9");
        case "id":
          return this.$t("SETTLEMENTS.TABLE.3");
        case "settlement_date":
          return this.$t("SETTLEMENTS.TABLE.4");
        case "discount":
          return this.$t("SETTLEMENTS.TABLE.17");
        default:
          return key;
      }
    },
    computeCSVData() {
      const settlement = this.selected;
      const result = [];
      if (settlement) {
        for (let key in settlement.payment_details) {
          let { group } = settlement;
          let {
            payment_id,
            merchant_payment_reference,
            merchant_order_reference,
            settlement_amount,
            currency,
            amount,
            discount,
            payment_date,
            settled_at,
            order_id,
            total_settlement_fee,
          } = settlement.payment_details[key];

          payment_date = this.formatDate(payment_date);
          settled_at = this.formatDate(settled_at);

          let settlementDetails = {
            payment_id,
            merchant_payment_reference,
            merchant_order_reference,
            amount,
            settlement_amount,
            currency,
            group,
            discount,
            payment_date,
            settled_at,
            order_id,
            total_settlement_fee,
          };

          result.push(settlementDetails);
        }
      }
      return result;
    },
  },
  computed: {
    visibleData() {
      const paymentDetails = this.selected
        ? Object.entries(this.selected)
        : [["id", "Not found"]];
      return paymentDetails.map(([key, value]) => {
        key = this.getLabelKey(key);
        return { key, value };
      });
    },
    detailFields() {
      if (this.visible) {
        return [
          { key: "key", label: this.selected.id, _style: "width:150px" },
          { key: "value", label: "", _style: "width:150px;" },
        ];
      }
      return [];
    },
  },
  mounted() {
    this.getSettlementDetailsByID();
  },
};
</script>
